<template>
  <div class="d-flex flex-column justify-center align-center">
    <div class="grid my-2">
      <div class="d-flex justify-space-between align-center">
        <div class="d-flex align-center">
          <span class="font onBackground--text" v-text="block.title"></span>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="mr-2 primaryText--text">
                <span class="font" v-text="showDefult.title"></span>
                <v-icon size="19">mdi-chevron-down</v-icon>
              </div>
            </template>
            <v-list class="py-0" dense>
              <v-list-item
                link
                v-for="(item, index) in showList"
                :key="item.id"
              >
                <v-list-item-title
                  class="py-0 medium-font"
                  @click="select(item, index)"
                  v-text="item.title"
                ></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <span @click="show_more" class="my-auto ml-2 font primaryText--text"
          >بیشتر</span
        >
      </div>
    </div>
    <splide v-if="slides.length >= 1" :options="options" :slides="slides">
      <splide-slide v-for="slide in slides" :key="slide.id">
        <product :slider="slide" />
      </splide-slide>
    </splide>
    <splide v-if="slides.length < 1" :options="options" :slides="slides">
      <splide-slide>
        <v-skeleton-loader
          type="image, article, actions"
          max-width="250"
          height="370"
        ></v-skeleton-loader>
      </splide-slide>
      <splide-slide>
        <v-skeleton-loader
          type="image, article, actions"
          max-width="250"
          height="370"
        ></v-skeleton-loader>
      </splide-slide>
      <splide-slide>
        <v-skeleton-loader
          type="image, article, actions"
          max-width="250"
          height="370"
        ></v-skeleton-loader>
      </splide-slide>
      <splide-slide>
        <v-skeleton-loader
          type="image, article, actions"
          max-width="250"
          height="370"
        ></v-skeleton-loader>
      </splide-slide>
    </splide>
  </div>
</template>
<style lang="scss" scoped>
.v-menu__content.theme--light.menuable__content__active {
  z-index: 0 !important;
}
</style>
<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import product from "../cards/product";
import { mapState } from "vuex";
import { blockList, viewblock } from "../../models/home";
import { viewbasket_products } from "../../../Basket/models/Basket";
import store from "@/store";
import { search_product_tag } from "@/views/shop/search/models/search";
export default {
  components: {
    Splide,
    SplideSlide,
    product,
  },
  props: ["options", "block"],
  computed: {
    ...mapState({
      blockList: (state) => state.home.blockList,
      products: (state) => state.home.blockProduct,
      selected_list: (state) => state.home.selected_list,
      search_fild: (state) => state.search.searchFild,
    }),
    showList() {
      let items = [];
      for (const block of this.blockList) {
        if (block.blockID == this.$props.block.id) {
          items = block.lists;
        }
      }
      return items;
    },
    showDefult() {
      let Defult = [];
      for (const block of this.blockList) {
        if (block.blockID == this.$props.block.id) {
          for (const selected of this.selected_list) {
            if (selected.block_id == this.$props.block.id) {
              Defult = block.lists[selected.index];
            }
          }
        }
      }
      return Defult;
    },
    slides() {
      let products = [];
      for (const product of this.products) {
        if (product.parentID == this.$props.block.id) {
          products = product.products;
        }
      }
      return products;
    },
  },
  data: () => ({
    blockID: "",
    items: [],
    benched: 0,
  }),
  mounted() {
    if (this.products.length < 1) {
      blockList(this.$props.block.id);
    }
  },
  methods: {
    select(select, index) {
      store.dispatch("selected_list", {
        index: index,
        block_id: this.$props.block.id,
      });
      viewblock(select.id, this.$props.block.id);
      viewbasket_products();
    },
    show_more() {
      let filters = localStorage.getItem("filter_search");
      if (filters) {
        filters = JSON.parse(filters);
        filters.page = 1;
      } else {
        filters = {};
        filters.page = 1;
      }
      switch (this.showDefult.filter_type) {
        case "1":
          filters.block_id = this.showDefult.id;
          filters.title = { title: this.showDefult.title, value: "block_id" };
          localStorage.setItem("filter_search", JSON.stringify(filters));
          setTimeout(() => {
            search_product_tag(filters);
          }, 500);
          break;
        case "2":
          filters.group = this.showDefult.filter_search;
          filters.title = { title: this.showDefult.title, value: "group" };
          localStorage.setItem("filter_search", JSON.stringify(filters));
          setTimeout(() => {
            search_product_tag(filters);
          }, 500);
          break;
        case "3":
          filters.tags = this.showDefult.filter_search;
          filters.title = { title: this.showDefult.title, value: "tags" };
          localStorage.setItem("filter_search", JSON.stringify(filters));
          setTimeout(() => {
            search_product_tag(filters);
          }, 500);
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.grid {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  width: 95%;
  align-items: center;
}
</style>